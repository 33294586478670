<div class="button-footer-container">
	<ng-content select="[leftButton]"></ng-content>
	<div class="button-layout">
		<ng-content select="[middleButton]"></ng-content>
	</div>
	<button
		class="button-margin"
		mat-raised-button
		color="primary"
		[disabled]="disabled"
		(click)="saveOrClose()"
	>
		{{ saveOrCloseButtonText }}
	</button>
</div>
