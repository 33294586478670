import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomFieldService } from './services/custom-field.services';
import { CustomFieldsListComponent } from './components/custom-fields-list/custom-fields-list.component';
import { CustomFieldDropdownComponent } from './components/custom-field-dropdown/custom-field-dropdown.component';
import { CustomFieldNumberComponent } from './components/custom-field-number/custom-field-number.component';
import { SharedUIMaterialModule } from 'src/app/shared/ui/material/shared-ui-material.module';
import { CustomFieldTextComponent } from './components/custom-field-text/custom-field-text.component';
import { MaterialModule } from 'src/app/common/material/material-module';
import { CustomFieldLinkComponent } from './components/custom-field-link/custom-field-link.component';

@NgModule({
	declarations: [
		CustomFieldsListComponent,
		CustomFieldNumberComponent,
		CustomFieldDropdownComponent,
		CustomFieldTextComponent,
		CustomFieldLinkComponent,
	],
	imports: [CommonModule, ReactiveFormsModule, SharedUIMaterialModule, MaterialModule],
	providers: [CustomFieldService],
	exports: [
		CustomFieldsListComponent,
		CustomFieldTextComponent,
		CustomFieldNumberComponent,
		CustomFieldDropdownComponent,
		CustomFieldLinkComponent,
	],
})
export class CustomFieldSharedModule {}
