import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Attachment } from 'src/app/app-data/models/Attachment';

@Component({
	selector: 'preview-carousel',
	templateUrl: './preview-carousel.component.html',
	styleUrls: ['./preview-carousel.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewCarouselComponent implements OnChanges {
	@Input() public slides: Attachment[];
	@Input() public selectedFile: Attachment;
	@Input() public width: number;
	@Input() public height: number;
	@Input() public selectFile: (file: Attachment) => void;

	public xPosition = 0;
	private currentIndex = 0;

	constructor() {}

	public ngOnChanges(changes: SimpleChanges): void {
		const files = changes['slides'];
		const file = changes['selectedFile'];
		if (files?.currentValue !== files?.previousValue || file?.currentValue !== file?.previousValue) {
			const currentIndex = this.slides.findIndex((item) => item.$id === this.selectedFile.$id);
			// Trigger movement to slide from film strip
			this.xPosition = this.calculateDistance(currentIndex);
			if (currentIndex !== -1) {
				this.currentIndex = currentIndex;
			}
		}
		// if image was deleted from another project member, go to the next image.
		if (this.slides.findIndex((attachment: Attachment) => attachment.$id === this.selectedFile.$id) === -1) {
			// if last image was deleted, go to the very last image that's not yet deleted in the list
			if (this.currentIndex >= this.slides.length) {
				this.xPosition = this.calculateDistance(this.slides.length - 1);
				this.selectFile(this.slides[this.slides.length - 1]);
			} else {
				this.xPosition = this.calculateDistance(this.currentIndex);
				this.selectFile(this.slides[this.currentIndex]);
			}
		}
	}

	public selectNextSlide() {
		const currentIndex = this.slides.findIndex((item) => item.$id === this.selectedFile.$id);
		const next = currentIndex + 1;
		const newIndex = next === this.slides.length ? 0 : next;
		this.xPosition = this.calculateDistance(newIndex);
		this.selectFile(this.slides[newIndex]);
	}

	public selectPreviousSlide() {
		const currentIndex = this.slides.findIndex((item) => item.$id === this.selectedFile.$id);
		const previous = currentIndex - 1;
		const newIndex = previous < 0 ? this.slides.length - 1 : previous;
		this.xPosition = this.calculateDistance(newIndex);
		this.selectFile(this.slides[newIndex]);
	}

	private calculateDistance(index: number): number {
		return -this.width * index;
	}
}
