import { fancyFirebaseGetters, fancyFirebaseReferencer } from '@touchplan/fancy-referencer';

/** A temp thing to check and see if a list of tickets has any attachment data for a temp float space fix */

export class HasAttachment {
	public getPathsForTicketsOnSamePlan(projectId: string, planId: string, ticketIds: string[]) {
		return ticketIds.map((ticketId) =>
			fancyFirebaseGetters.getTicketAttachmentsPath(projectId, planId, ticketId)
		);
	}

	public fetchAttachmentsForTickets(ticketPaths: string[], projectId: string) {
		return Promise.all(
			ticketPaths.map((path) => fancyFirebaseReferencer.getRef(path, projectId).once('value'))
		);
	}

	public hasAnyAttachments(ticketPaths: string[], projectId: string) {
		return this.fetchAttachmentsForTickets(ticketPaths, projectId).then((list) => {
			return list.some((t) => t.exists());
		});
	}
}

export const hasAttachment = new HasAttachment();
