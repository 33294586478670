import { IFirebaseList } from './IFirebaseList';
import { IProvider } from './tenant-interfaces/IProvider';

// If user does not have a tenantId, then the default tenantId will be used.
// NOTE: this is duplicated from fancyFirebaseReferencer due to some current bizarre dependency chains
// https://touchplan.slack.com/archives/C01B31FSFT2/p1691675038249659
const DEFAULT_TENANT_ID: string = 'touchplan';
const DEFAULT_PROVIDER: string = 'password';

interface IFirebaseTenantMetadata {
	name: string;
	providers: IFirebaseList<IProvider>;
	// isExclusive?: boolean; //in original outdated spec, not currently in use
	projectAccess?: {
		[key: string]: 'exclusive' | 'inclusive';
	};
	firebaseTenantId: string;
}

export interface ITenantMetadata extends IFirebaseTenantMetadata {
	providerId: string;
}

export enum TenantErrors {
	NotLoaded = 'tenant data not loaded',
	NotCached = 'tenant data not cached',
}

const defaultTenantData: ITenantMetadata = {
	name: DEFAULT_TENANT_ID,
	providers: {
		[DEFAULT_PROVIDER]: {
			providerId: DEFAULT_PROVIDER,
			enabled: true,
		},
	},
	providerId: DEFAULT_PROVIDER,
	firebaseTenantId: DEFAULT_TENANT_ID,
};

/**
 * Meant to encapsulate the idea that we have two different tenantId's _extremely_ explicitly.
 * Requires initialization of a tenant by creating one. Then stores them in an internal static jar.
 * Allows for arbitrarily grabbing a tenant by either of it's id's
 */
export class Tenant {
	private static _authTenantIdCache: Map<string, Tenant> = new Map();
	private static _rtdbTenantIdCache: Map<string, Tenant> = new Map();

	private _loaded: boolean = false;

	private _rtdbTenantId: string | undefined;
	private _authTenantId: string | undefined;
	private _tenantMetadata: ITenantMetadata | undefined;

	// not really worth getter/setter-ing
	// public tenantMetadata: ITenantMetadata | undefined;

	public constructor(authTenantId?: string, rtdbTenantId?: string, tenantMetadata?: IFirebaseTenantMetadata) {
		if (authTenantId) {
			this.authTenantId = authTenantId;
		}
		if (rtdbTenantId) {
			this.rtdbTenantId = rtdbTenantId;
		}
		if (tenantMetadata) {
			this.tenantMetadata = tenantMetadata;
		}
	}

	public set tenantMetadata(tenantMetadata: IFirebaseTenantMetadata | undefined) {
		if (tenantMetadata) {
			this._tenantMetadata = this._pretendOnlyOneProvider(tenantMetadata);
		} else {
			this._tenantMetadata = tenantMetadata;
		}
	}
	public get tenantMetadata(): ITenantMetadata | undefined {
		return this._tenantMetadata;
	}

	/** a default tenant safe way to compare tenantIds */
	public static isSameTenantId(tenantIdA?: string, tenantIdB?: string): boolean {
		return (
			tenantIdA === tenantIdB ||
			(!tenantIdA && !tenantIdB) ||
			(!tenantIdA && tenantIdB === DEFAULT_TENANT_ID) ||
			(tenantIdA === DEFAULT_TENANT_ID && !tenantIdB)
		);
	}

	public static getDefaultTenant(): Tenant {
		if (this._rtdbTenantIdCache.has(DEFAULT_TENANT_ID)) {
			return this._rtdbTenantIdCache.get(DEFAULT_TENANT_ID)!;
		}
		return Tenant.create(DEFAULT_TENANT_ID, DEFAULT_TENANT_ID, defaultTenantData);
	}

	/** Grab an instantiated tenant using the auth id */
	public static getByAuthTenantId(tenantId: string): Tenant {
		if (this._authTenantIdCache.has(tenantId)) {
			return this._authTenantIdCache.get(tenantId)!;
		}
		throw new Error(TenantErrors.NotCached);
	}

	/** Grab an instantiated tenant using the rtdb id */
	public static getByRtdbTenantId(tenantId: string): Tenant {
		if (this._rtdbTenantIdCache.has(tenantId)) {
			return this._rtdbTenantIdCache.get(tenantId)!;
		}
		throw new Error(TenantErrors.NotCached);
	}

	/** Creates a new tenant, basically just wraps the constructor for... "parity" */
	public static create(
		authTenantId: string,
		rtdbTenantId: string,
		tenantMetadata?: IFirebaseTenantMetadata
	): Tenant {
		return new Tenant(authTenantId, rtdbTenantId, tenantMetadata);
	}

	//a semi-temp solution to allow for easy access of the single providerId
	//for the forseeable future _there will only be one_
	private _pretendOnlyOneProvider(firebaseTenantData: IFirebaseTenantMetadata): ITenantMetadata {
		let providerId: string = '';
		if (firebaseTenantData?.providers) {
			providerId = Object.values(firebaseTenantData.providers)[0].providerId;
		}
		return { ...firebaseTenantData, providerId };
	}

	private _recheckLoaded(): void {
		if (this._authTenantId && this._rtdbTenantId) {
			this._loaded = true;
		}
	}

	private _shuffleCache(
		oldId: string | undefined,
		newId: string | undefined,
		cache: Map<string, Tenant>
	): void {
		if (oldId === newId || !newId) {
			return;
		}
		if (oldId && cache.has(oldId)) {
			cache.delete(oldId);
		}
		cache.set(newId, this);
	}

	public get loaded(): boolean {
		return this._loaded;
	}

	public set rtdbTenantId(tenantId: string) {
		this._shuffleCache(this._rtdbTenantId, tenantId, Tenant._rtdbTenantIdCache);
		this._rtdbTenantId = tenantId;
		this._recheckLoaded();
	}
	public get rtdbTenantId(): string {
		if (!this._rtdbTenantId) {
			throw new Error(TenantErrors.NotLoaded);
		}
		return this._rtdbTenantId;
	}

	public set authTenantId(tenantId: string) {
		this._shuffleCache(this._authTenantId, tenantId, Tenant._authTenantIdCache);
		this._authTenantId = tenantId;
		this._recheckLoaded();
	}
	public get authTenantId(): string {
		if (!this._authTenantId) {
			throw new Error(TenantErrors.NotLoaded);
		}
		return this._authTenantId;
	}

	public get isDefaultTenant(): boolean {
		return this.authTenantId === DEFAULT_TENANT_ID;
	}
}
