import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { subjectSubscribeWithoutComplete } from './services/rxjs-internal-extensions';

//possible enhancements
// - respect completion configuration option (subscribe to entire observable in the internal subject). Currently the internal behavior subjects
// subscribe to only the next and error emissions and ignores complete. Means you can pass in source observables that complete but will still
// be able to get the last values of of them because the internal behavior subjects will _never_ complete. In theory it's possible there will
// be a case where that's undesirable, so... add configuration options if so.
// - default value suppression configuration option. Config option to just... turn off that weird random number thing.

/**
 * Serves as an observable cache to be used in lazy loading cases. Allows for requesting an observable, wrapping it in a behavior subject,
 * and adding it to a cache so subsequent requests don't repeat a network request or other sufficiently complex thing.
 *
 * Usage:
 * ```
 * private adminshipJar = new ObservableJar((projectId: string) => {
 * 	return this.fetchAdminship(projectId); //this function returns an observable
 * });
 * jar.get('thingWithThisId').subscribe();
 * //or
 * jar.get$(observableThatEmitsIds).subscribe();
 * ```
 */
export class ObservableJar<T> {
	private jar = new Map<string, { subject: BehaviorSubject<T>; obs: Observable<T> }>();
	private uniqueNotifierSubject = new Subject<{ id: string; ob$: Observable<T> }>();
	/** emits whenever an observable is initially registered with the ObservableJar. Allow for finer grained handling of the data */
	public uniqueNotifier = this.uniqueNotifierSubject.asObservable();

	/**
	 * @param sourceObservableGetter A callback function which is expected to return an observable emitting data that corresponds to the passed
	 * in id. So if this is intended to be a cache of project data, the id will be a project id, and the observable will be a subscription to
	 * firebase for that project.
	 * This method will be called once per unique id unless that id is cleaned up and needs to be re-fetched.
	 */
	constructor(private sourceObservableGetter: (id: string) => Observable<T>) {}

	/**
	 * returns true to indicate that the jar contains that observable
	 */
	public has(id: string) {
		return this.jar.has(id);
	}

	public get keys() {
		return this.jar.keys();
	}

	/**
	 * Returns the requested observable. If cached, the cached version will be returned. Else a new request will be made via the fallbackGetter
	 */
	private getViaId$(id: string) {
		if (this.has(id)) {
			return this.jar.get(id).obs;
		}

		// Want 3 things at the same time
		// - observable to get cached sync before it emits to prevent race conditions
		// - to prevent the default value of the BehaviorSubject from being emitted and/ or other superfluous emissions
		// - to ensure that we don't get real values skipped when multiple things subscribe
		// We can't start will null and filter it because that could be a real value. We can't skip because that would result in subsequent
		// subscriptions after the first skipping a real value. And we can't create the BehaviorSubject until we know the real value because
		// that will prevent it from being added to the cache syncronously.
		// So... we'll use the firebase id method. Make a random number as the init, filter it out and rely on the extremely small odds to avoid collisions.
		const randomNumber = Math.random();

		const subject = new BehaviorSubject<T>(randomNumber as any);
		const obs = subject.asObservable().pipe(filter((val) => val !== (randomNumber as any)));
		this.jar.set(id, { subject, obs });

		// this.sourceObservableGetter(id).subscribe(subject); //version handles completion
		this.sourceObservableGetter(id).subscribe(subjectSubscribeWithoutComplete(subject));

		this.uniqueNotifierSubject.next({ id, ob$: obs });

		return obs;
	}
	/**
	 * Returns the requested observable. Can support a changing id.
	 */
	public get$(id$: string | Observable<string>) {
		if (typeof id$ === 'string') {
			return this.getViaId$(id$);
		}

		return id$.pipe(switchMap((id) => this.getViaId$(id)));
	}

	public getSync(id: string) {
		return this.jar.get(id)?.subject.getValue();
	}

	/** End the subscriptions and remove from cache. Is a no-op if there's nothing to delete */
	public delete(id: string) {
		if (!this.has(id)) {
			return;
		}
		const item = this.jar.get(id);
		item.subject.complete();
		this.jar.delete(id);
		this.uniqueNotifierSubject.next({ id, ob$: null });
	}
}
