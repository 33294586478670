import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/common/material/material-module';
import { CustomFieldFilterComponent } from './custom-field-filter.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedUIMaterialModule } from 'src/app/shared/ui/material/shared-ui-material.module';
import { DialogReusableModule } from '../../dialog-reusable/dialog-reusable.module';
import { CustomFieldSharedModule } from 'src/app/modules/custom-field/custom-field-shared.module';

@NgModule({
	declarations: [CustomFieldFilterComponent],
	imports: [
		CommonModule,
		MaterialModule,
		ReactiveFormsModule,
		DialogReusableModule,
		SharedUIMaterialModule,
		CustomFieldSharedModule,
	],
	providers: [],
	exports: [CustomFieldFilterComponent],
})
export class CustomFieldFilterModule {}
