import { Component, OnInit } from '@angular/core';
import { GenericDialogComponent } from '../generic-dialog.component';
import { cssThemes } from 'src/app/app-data/constants/cssThemes';

@Component({
	selector: 'selection-dialog',
	templateUrl: './selection-dialog.component.html',
	styleUrls: ['./selection-dialog.component.scss'],
})
export class SelectionDialogComponent extends GenericDialogComponent implements OnInit {
	public cssTheme: string = cssThemes.primary;
	public choicesGroup = this.formBuilder.group({
		choice: this.data.defaultChoice ? this.data.defaultChoice : this.data.choices[0],
	});

	public onButtonChoiceSelect() {
		this.dialogRef.close(this.choicesGroup.value.choice);
	}
}
